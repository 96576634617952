<template>
  <div>
    <s-btn class="link center opacity-50 normal" height="65" @click.native="openModal">
      <s-icon width="20" color="white" class="mr-10">door-open</s-icon>
      {{ $t("rooms.new") }}
    </s-btn>
    <div class="d-flex pt-10 pb-10 pl-5 pr-5 font-14">
      <div class="grow pa-5 opacity-50">{{ rooms.length }}  {{ $t("rooms.rooms") }}</div>
         <div class="pa-5 opacity-50 active capital"> {{ $t("attendees.all") }}</div>
      <!-- <div class="pa-5 opacity-50">MINE</div> -->
    </div>
    <div
      v-for="item in filteredRooms"
      :key="item.idx"
      @click="edit(item)"
      class="pa-10 d-flex item pointer"
    >
      <div
        class="box center mr-10 mt-4"
        :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }"
      >
        <div class="font-28">{{ item.title| letter }}</div>
      </div>
      <div>
        <div>{{ item.title }}</div>
        <div class="font-12 weight-300 mt-5">
          {{ $t("rooms.created") }} {{ item.createdAt | created }}
        </div>
        <div class="font-12 weight-300 mt-8">
           {{ $t("rooms.administrator") }}:  {{ item.manager.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  filters: {
    created(val) {
      return dayjs(val).format("D/MMM/YYYY");
    },
    letter(val) {
      if (!val) return;
      return val.charAt(0);
    },
  },
  computed: {
    filteredRooms(){
      return this.rooms.filter(el => !el.isDeleted)
    },
    ...mapState("rooms", ["rooms"]),
  },
  methods: {
    async openModal() {
      let valid = await this.$store.dispatch("user/isNewRoomValid");
      if (!valid) return this.$store.commit("modals/billingModal", true);
      this.$store.commit("rooms/clearRoom");
      this.$store.commit("modals/roomModal", true);
    },
    edit(val) {
      if (this.$router.currentRoute.name !== "Rooms")
        this.$router.push("/rooms");
    },
  },
  mounted() {
    this.$store.dispatch("rooms/getRoomsInformation");
  },
};
</script>

<style scoped>
.box {
  width: 37px;
  height: 37px;
}

.normal{
  white-space: normal;
}

.active {
  opacity: 1;
}

.link {
  height: 65px;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: #243748;
}

.item:hover {
  background-color: #425464;
}
</style>
