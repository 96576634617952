<template>
  <div class="sra transitions ">
    <div class="center pt-15 pb-5">
      <s-btn
        icon
        @click.native="meeting"
        class="green font-18"
        height="40"
        width="280"
        font="18"
      >
        <s-icon width="20" color="white">video</s-icon>
        <div>{{ $t("toolbars.sra.start") }}</div>
      </s-btn>
    </div>
    <div class="center pt-10 pb-20">
      <div class="text">
        {{ $t("toolbars.sra.note") }}
      </div>
    </div>
    <div class="d-flex">
      <s-btn
        height="40"
        @click.native="change('schedule')"
        :class="{ active: state == 'schedule' }"
        class="grow opacity-50"
      >
        {{ $t("toolbars.sra.schedule") }}
      </s-btn>
      <s-btn
        height="40"
        @click.native="change('rooms')"
        :class="{ active: state == 'rooms' }"
        class="grow opacity-50  normal"
        >{{ $t("toolbars.sra.rooms") }}</s-btn
      >
      <s-btn
        height="40"
        @click.native="change('attendees')"
        :class="{ active: state == 'attendees' }"
        class="grow opacity-50"
        >{{ $t("toolbars.sra.attendees") }}</s-btn
      >
    </div>
    <ScheduleSRA v-if="state == 'schedule'" />
    <RoomsSRA v-if="state == 'rooms'" />
    <AttendeesSRA v-if="state == 'attendees'" />
  </div>
</template>

<script>
import ScheduleSRA from "../Schedule/ScheduleSRA";
import RoomsSRA from "../Rooms/RoomsSRA";
import AttendeesSRA from "../Attendees/AttendeesSRA";

export default {
  components: {
    ScheduleSRA,
    RoomsSRA,
    AttendeesSRA,
  },
  methods: {
    change(change) {
      this.$store.commit("toolbars/changeSRA", change);
    },
    meeting() {
      this.$store.commit("modals/meetingModal", true);
    },
  },
  computed: {
    state() {
      return this.$store.state.toolbars.sra;
    },
  },
};
</script>

<style scoped lang="less">
.sra {
  min-width: 355px;
  width: 355px;
  height: 100%;
  background-color: #37424d;
  overflow-y: auto;
  overflow-y: overlay;
}

.text {
  width: 280px;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  color: #ffffff;
  text-align: center;
}
.active {
  opacity: 1;
}

.normal{
  white-space: normal;
}
@media only screen and (max-width: 795px) {
  .sra {
    height: calc(100% - 60px);
  }
}
</style>
