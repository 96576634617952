<template>
  <div>
    <s-btn class="link center opacity-50" height="65" @click.native="openModal">
      <s-icon width="20" color="white" class="mr-10">account-plus</s-icon>
      {{ $t("attendees.add_an_attendee") }}
    </s-btn>
    <div class="d-flex pt-10 pb-10 pl-5 pr-5 font-14">
      <div class="grow pa-5 opacity-50">
        {{ attendees.length }} {{ $t("attendees.attendees") }}
      </div>
      <div class="pa-5 opacity-50 active capital"> {{ $t("attendees.all") }}</div>
      <!-- <div class="pa-5 opacity-50">MINE</div> -->
    </div>
    <div
      v-for="item in attendees"
      :key="item.idx"
      @click="edit(item)"
      class="pa-10 d-flex pointer item pointer"
    >
      <div
        class="box center mr-10 mt-4"
        :style="{ backgroundColor: 'var(--sm-color-' + item.color + ')' }"
      >
        <s-icon color="white" width="28">monitor-lock</s-icon>
      </div>
      <div>
        <div>{{ item.name }}</div>
        <div class="font-14 mt-4">{{ item.email }}</div>
        <div class="font-14 weight-300 mt-5">{{ item.phone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("attendees", ["attendees"]),
  },
  methods: {
    openModal() {
      this.$store.commit("attendees/clearAttendee");
      this.$store.commit("modals/attendeeModal", true);
    },
    edit(val) {
      // this.$store.commit("attendees/editAttendee", val);
      // this.$store.commit("modals/attendeeModal", true);
      if (this.$router.currentRoute.name !== "Attendees")
        this.$router.push("/attendees");
    },
  },
  mounted() {
    this.$store.dispatch("attendees/getAttendeesInformation");
  },
};
</script>

<style scoped>
.box {
  width: 37px;
  height: 37px;
}

.item {
  height: 60px;
}

.item:hover {
  background-color: #425464;
}

.active {
  opacity: 1;
}

.link {
  height: 65px;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: #243748;
}

.link:hover {
  background-color: #425464;
}
</style>
