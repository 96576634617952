<template>
  <div>
    <router-link
      to="/schedule"
      class="link center opacity-50"
      height="65"
      color="#243748"
      @click.native="close()"
    >
      <s-icon width="20" color="white" class="mr-10">monitor-lock</s-icon>
       {{ $t("schedule.schedule_a_meeting") }}
    </router-link>
    <div class="d-flex pt-10 pb-10 pl-5 pr-5 font-14">
      <div class="grow pa-5 opacity-50">{{ events.length }}  {{ $t("schedule.meetings") }}</div>
        <div class="pa-5 opacity-50 active capital"> {{ $t("attendees.all") }}</div>
      <!-- <div class="pa-5 opacity-50">MINE</div> -->
    </div>

    <div
      @click="editEvent(item)"
      v-for="item in events"
      :key="item.idx"
      class="pa-10 d-flex item pointer"
    >
      <div
        class="box center mr-10 mt-4"
        :style="{ backgroundColor: item.color }"
      >
        <s-icon color="white" width="28">monitor-lock</s-icon>
      </div>

      <div class="grow">
        <div class="d-flex">
          <div class="grow">
            <div>{{ item.title }}</div>
            <div class="mt-4">{{ item.room }}</div>
          </div>
          <div>
            <div
              class="d-flex justify-end mr-10"
              :class="{ ' opacity-50': !alert(item.start) }"
            >
              <s-icon v-if="alert(item.start) == true" color="red" width="21"
                >clock-alert</s-icon
              >
              <s-icon v-else color="white" width="21">monitor-star</s-icon>
            </div>
            <div
              class="font-12 weight-300 mt-8"
              :class="{ 'red--text': alert(item.start) }"
            >
              {{ item.start | countDown }}
            </div>
          </div>
        </div>
        <div class="font-12 weight-300 mt-3">
          {{ item.start | start }} - {{ item.end | end }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import { mapState } from "vuex";
dayjs.extend(relativeTime);
dayjs.extend(timezone);
export default {
  data() {
    return {};
  },
  methods: {
    alert(val) {
      let aa = dayjs().to(dayjs.unix(val), true);
      aa = aa.split(" ");
      let num = Number(aa[0]);
      if (num == "a") num = 1;
      let str = aa[1];
      if ((num < 30 || num == "a") && str == "minutes") return true;
    },
    close() {
      this.$store.commit("toolbars/toggleSidebarControl", false);
    },
    async editEvent(val) {
      this.$store.dispatch("schedule/getEventDetailsByID", val);
      this.$router.push("/schedule/edit");
    },
  },
  computed: {
    ...mapState("calendar", ["events"]),
  },
  filters: {
    start(val) {
      return dayjs.unix(val).format("dddd, hh:mm A");
    },
    end(val) {
      return dayjs.unix(val).format("hh:mm A");
    },
    countDown(val) {
      return dayjs().to(dayjs.unix(val), true);
    },
    alert(val) {
      let aa = dayjs().to(dayjs.unix(val), true);
      aa = aa.split(" ");
      let num = Number(aa[0]);
      let str = aa[1];
      if (num < 30 && str == "minutes") return true;
    },
  },
  async mounted() {
    this.$store.dispatch("schedule/getScheduleInformation");
    setInterval(() => {
      this.$store.dispatch("schedule/getScheduleInformation");
    }, 1000 * 60);
  },
};
</script>

<style scoped lang="less">
.box {
  width: 37px;
  height: 37px;
}

.active {
  opacity: 1;
}

.link {
  height: 65px;
  width: 100%;
  font-size: 18px;
  color: white;
  background-color: #243748;
  display: flex;
}

.link:hover {
  background-color: #425464;
}

.item:hover {
  background-color: #425464;
}
</style>
