<template>
  <div class="sidebar pa-0" :class="{ sideBar: state }">
    <SRA v-if="state" class="bigCollapse" />
    <LeftToolbar v-if="!state" class="smallCollapse" />
  </div>
</template>

<script>
import SRA from "@/components/Toolbars/SRA.vue";
import LeftToolbar from "@/components/Toolbars/LeftToolbar.vue";
import { mapState } from "vuex";
export default {
  components: {
    SRA,
    LeftToolbar,
  },
  computed: {
    ...mapState("user", ["email"]),
    state() {
      return this.$store.state.toolbars.sidebar;
    },
  },
  mounted() {
    const mq = window.matchMedia("(max-width: 795px)");
    mq.addListener((mq) => {
      if (mq.matches) {
        this.$store.commit("toolbars/toggleSidebarControl", false);
      } else {
        // this.$store.commit("toolbars/toggleSidebarControl", true);
      }
    });
  },
};
</script>



<style scoped lang="less">
.sidebar {
  z-index: 3;
  height: calc(100vh - var(--top-toolbar-height) - var(--footer-height));
}

*::-webkit-scrollbar-thumb {
  background-color: #cfd0d100;
  border-radius: 0px;
}

@media only screen and (max-width: 795px) {
  .bigCollapse {
    position: absolute;
    z-index: 3;
  }

  .sideBar {
    min-width: 60px;
  }
}

@media only screen and (max-height: 390px) {
  .sidebar {
    overflow: overlay;
  }
}

@media only screen and (max-width: 576px) {
  .smallCollapse {
    transform: translateX(-60px);
    transition: all ease 0.2s;
    position: absolute;
    height: 100%;
    z-index: 3;
  }

  .bigCollapse {
    transform: translateX(-355px);
    transition: all ease 0.2s;
    position: absolute;
    height: 100%;
    z-index: 3;
  }
}
</style>
