var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"white-overlay"},[_c('div',{staticClass:"top",on:{"mouseleave":function($event){return _vm.changeOut()}}},_vm._l((_vm.top),function(item,index){return _c('button',{key:index,staticClass:"item bg relative uppercase",class:{
          active: _vm.$route.path == item.to || _vm.$route.name == item.name,
          greenBg: item.type == 'button',
          // none:   item.hide && role == 'user'
        },on:{"click":function($event){return _vm.select(item)},"mouseover":function($event){_vm.mouseOnHover = true},"mouseleave":function($event){_vm.mouseOnHover = false}}},[_c('div',{staticClass:"sr"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"h100 w100 center",on:{"mouseover":function($event){return _vm.change(index)}}},[_c('s-icon',{attrs:{"color":"grey"}},[_vm._v(_vm._s(item.icon))]),(_vm.hover == index && _vm.showHover)?_c('div',{staticClass:"hover center px-10",class:{ greenBg: item.type == 'button' }},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)])}),0),_c('div',{staticClass:"bottom",on:{"mouseleave":function($event){return _vm.changeOut()}}},_vm._l((_vm.bottom),function(item,index){return _c('button',{key:index,staticClass:"item bg-bottom relative uppercase",class:{
          active: _vm.$route.path == item.to || _vm.$route.name == item.name
        },on:{"click":function($event){return _vm.select(item)},"mouseover":function($event){_vm.mouseOnHover = true},"mouseleave":function($event){_vm.mouseOnHover = false}}},[_c('div',{staticClass:"sr"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"h100 w100 center",on:{"mouseover":function($event){return _vm.change(_vm.top.length + index)}}},[_c('s-icon',{attrs:{"color":"grey"}},[_vm._v(_vm._s(item.icon))]),(_vm.hover == _vm.top.length + index && _vm.showHover)?_c('div',{staticClass:"hover center px-10"},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }